.modal-container {
  @apply w-full md:w-1/3 md:h-auto overflow-x-hidden flex flex-col h-screen overflow-scroll bg-white rounded-lg md:overflow-hidden focus:outline-none z-50 p-4 max-w-screen-xl;
}

.modal-overlay {
  @apply bg-black bg-opacity-20 w-screen h-screen absolute top-0 left-0 items-center justify-center flex overflow-hidden z-50;
}

.close-button {
  @apply h-12 w-12 rounded-full bg-zimx-indigo-light text-gray-600;
}

.modal-divider {
  @apply w-full border-b  mt-4 mb-8;
}

.modal-title-container {
  @apply font-bold text-2xl flex justify-between items-center;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
