.product-item-container {
  @apply w-full items-start  shadow-md hover:shadow-2xl hover:scale-105 col-span-2 md:col-span-1 flex flex-wrap  overflow-hidden rounded-md cursor-pointer ease-in bg-white;
}

.product-item-img {
  @apply w-full bg-cover aspect-square border-white;
}

.product-item-details-row {
  @apply flex flex-row justify-between items-center pb-4 px-4 w-full pt-2;
}

.product-item-details-col {
  @apply flex flex-col;
}

.product-item-details-logo {
  @apply w-8 h-8 rounded-full border-2;
}

.product-item-details-brand-container {
  @apply pt-1 flex items-center text-sm;
}

.product-item-details-name {
  @apply font-rounded overflow-hidden truncate font-bold overflow-ellipsis text-gray-700;
}

.product-item-details-price {
  @apply flex-1 text-2xl p-0 m-0 flex items-center justify-end font-bold text-right text-zimx-indigo-dark;
}

