/* <div className="flex w-full justify-center">
      <div className="flex w-full items-start max-w-screen-xl bg-gray-100 min-h-screen flex-col p-4 relative"> */

.screen-container-outer-container {
  @apply w-full flex items-center flex-col min-h-screen bg-gray-100;
}

.screen-container-inner-container {
  @apply flex  items-start flex-col p-4 relative max-w-screen-xl w-full pt-8 sm:px-4;
}

.screen-container-footer {
  @apply max-w-screen-xl flex-1
}
