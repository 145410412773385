body {
	min-height: 100vh;
}

.navbar {
	color: red !important;
	margin-left: 0 !important;
	margin-top: 0 !important;
	padding: 0 !important;
	margin-right: 2em;
}

.navbar-container {
	background: linear-gradient(0deg, white, 30%, #9e9dde);
	height: 30vh;
}

.navbar-brand {
	font-weight: bold;
	margin-left: 1.5em;
	margin-top: 0.4em;
	font-size: 3em;
	color: #433054 !important;
	font-family: 'Varela Round', sans-serif;
	padding: 0;
}

.navbar-nav {
	align-self: flex-end !important;
}

.nav-link {
	font-size: 1.4em;
	color: rgba(67, 48, 84, 0.8) !important;
	font-weight: 600;
	font-family: 'Varela Round', sans-serif;
}

.nav.navbar-nav .nav-link :hover {
	color: red !important;
}

.search-box-container {
	align-self: center;
	width: 60vw;
	margin-left: 20vw;
	margin-top: 5vw;
	height: 2em;
	background-color: #e5ecf3;
	font-family: 'Varela Round', sans-serif;
	font-size: 2em;
	border-radius: 10px;
	padding-left: 10px;
	display: flex;
	align-items: center;
	overflow: hidden;
}

.search-box {
	height: 100%;
	background-color: #e5ecf3;
	border: 0;
	margin-left: 20px;
	font-family: inherit;
	font-weight: 600;
	font-size: inherit;
	box-shadow: none !important;
}

.search-box:focus {
	background-color: #e5ecf3;
	border: 0;
}
