.input.input-medium {
	height: 40px !important;
	font-size: 0.8em;
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}

.input {
	margin-bottom: 1em;
	height: 60px !important;
	background-color: #f2f2fe !important;
	font-weight: 500 !important;
	font-size: 1.2em !important;
}

.input:focus {
	box-shadow: none !important;
	border-color: #6d5c7d !important;
	border-width: 2px;
}

.medium-button {
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	background-color: #6d5c7d !important;
	border-color: #6d5c7d !important;
}

.variation-properties {
	width: 100%;
}

.variation-card {
	min-height: 200px;
	border-radius: 10px;
	width: 100%;

	padding: 10px;
	background-color: white;
	box-shadow: 0 0 10px #9e9dde;
	margin-bottom: 20px;
}

.property-label {
	font-weight: bold;
	color: #433054;
	/* font-size: 1.2em; */
	font-family: 'Varela Round', sans-serif;
	margin-left: 8px;
}

.variation-properties-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.variation-prop-grid {
	margin-top: 20px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
	place-items: center;
	margin-bottom: 20px;
}

.grid-cols-2 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 12px;
}

.heading {
	margin-bottom: 20px;
	margin-left: 8px;
}

.variation-pill {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	background-color: #94378dad;
	padding: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	box-shadow: 0 0 15px #eee;
}

.variation-pill-remove {
	background-color: #94378d;
	border-radius: 14px;
	height: 28px;
	width: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.variation-pill-label {
	margin-top: 0px;
	margin-bottom: 0px;
	margin: 0;
	color: white;
	font-weight: 500;
}

.price-input {
	width: 80px;
	height: 40px !important;
	border-radius: 10px;
	margin-bottom: 0 !important;
	text-align: end;
}

.pricing-text {
	margin-bottom: 0px;
}

.pricing-row {
	padding-top: 10px;
	padding-bottom: 10px;
}

.img-selector-btn {
	box-shadow: 0 0 10px #433054;
	border-radius: 10px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
