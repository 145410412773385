.featured-product-image {
	width: 400px;
	background-color: #9e9dde;
	/* height: 30vh; */
	height: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	opacity: 0.4;
	font-size: 2em !important;
	font-weight: 700 !important;
	color: white;
	font-family: 'Varela Round', sans-serif;
}

.product-name {
	font-family: 'Varela Round', sans-serif;
	font-weight: 600;
	font-size: 2em;
	margin-top: 20px;
	margin-left: 8px;
	color: #433054;
}

.nav-link.product-nav-link {
  @apply font-rounded text-zimx-indigo-dark font-bold p-2 text-lg
}

.nav-link.product-nav-link.active {
	border-bottom: red solid 3px;
}

.nav {
	justify-content: space-between;
}

.featured-product-image > p {
	margin-bottom: 0px;
	margin-left: 1em;
}



.flex-full-container {
	flex: 1;
	display: flex;
	padding: 0;
	margin: 0;
}

.product-details-container {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.fill {
	flex-grow: 1;
}

.next-button {
	justify-self: flex-end;
	margin-bottom: 20px;
}

.full-width-row {
	width: 100%;
}

.button-outline {
	border-width: 4px;
	border-color: #6d5c7d !important;
	background-color: transparent !important;
	font-family: 'Varela Round', sans-serif;
	color: #6d5c7d;
}

.button-outline:hover {
	background-color: #6d5c7d !important;
	color: white;
}

.button-outline:focus {
	background-color: #6d5c7d !important;
	color: white;
}

.cancel-button {
	width: 100% !important;
	height: 60px;
	background-color: #bab0d6 !important;
	border-color: transparent !important;
	outline: transparent !important;
	font-size: 1.5em !important;
	font-weight: 700 !important;
	color: #433054 !important;
}

.button-outline:active {
	background-color: #6d5c7d !important;
	color: white;
}

.registration-button {
	background-color: #6d5c7d !important;
	border-color: #6d5c7d !important;
	height: 60px;
	font-size: 1.5em !important;
	font-weight: 700 !important;
}

.variation {
	background-color: white;
	height: 60px;
	display: flex;
	/* align-items: center; */
	justify-content: space-between;
	box-shadow: 0 0 5px #9e9dde;
	border-radius: 10px;
	flex-direction: row;
	overflow: hidden;
	cursor: pointer;
}

.variation:hover {
	box-shadow: 0 0 10px #9e9dde;
}

.variation-label {
	font-weight: bold;
	font-size: 1.5em;
	color: #433054;
	font-family: 'Varela Round', sans-serif;
	text-align: left;
	margin: 0;
}

.variation-label-container {
	display: flex;
	align-items: center;
	flex: 2;
	margin-left: 20px;
  
}

.variation-design-small-circle {
	margin-left: 5px;
	width: 14px;
	height: 14px;
	border-radius: 7px;
	background-color: rgba(167, 111, 160, 0.7);
}

.variation-design-smaller-circle {
	/* position: absolute;
	margin-top: 2px;
	margin-left: -85px; */
	/* z-index: -10; */
	margin-left: 25px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: rgba(128, 76, 121, 0.7);
}

.variation-design-smallest-circle {
	/* position: absolute;
	margin-top: 14px;
	margin-left: -90px; */
	/* z-index: -10; */
	margin-left: 10px;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background-color: rgba(86, 52, 82, 0.7);
}

.variation-design-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: flex-end;
	padding-bottom: 10px;
}



.popular-variations-container {
	margin-top: 10px;
	display: grid;
	column-gap: 12px;
	row-gap: 12px;
	grid-template-columns: 1fr 1fr;
	/* grid-auto-columns: min-content; */
}

.variation-header {
	font-family: 'Varela Round', sans-serif;
	font-weight: 600;
	margin-top: 20px;
	margin-left: 8px;
	color: #6c6b6d;
}
