html body {
	font-family: fa5-proxima-nova, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

.SignInScreen {
	display: grid;
	grid-template-columns: 12fr 10fr;
	min-width: 100vw;
	min-height: 100vh;
}

.logo {
  @apply ml-5 my-4 font-rounded text-zimx-indigo-dark font-bold text-5xl;
}

.form-container {
	display: flex;
	flex-direction: column;
	padding-top: 20%;
}

.input {
	margin-bottom: 1em;
	height: 60px !important;
	background-color: #f2f2fe !important;
	font-weight: 500 !important;
	font-size: 1.2em !important;
}

.button {
	width: 100% !important;
	height: 60px;
	background-color: #c16c86 !important;
	border-color: #c16c86 !important;
	outline: yellow;
	font-size: 1.5em !important;
	font-weight: 700 !important;
	margin-top: 0.4em;
}

.social-button {
	background-color: white !important;
	border-color: #433054 !important;
	color: #433054 !important;
	text-align: left !important;
	border-width: 2px !important;
	font-size: 1.3em !important;
}

.registration-button {
	background-color: #6d5c7d !important;
	border-color: #6d5c7d !important;
	height: 60px;
	font-size: 1.5em !important;
	font-weight: 700 !important;
}

.button-header {
	font-family: 'Varela Round', sans-serif;
	font-size: 1.2em;
	font-weight: 600;
	margin-bottom: inherit;
	margin-right: 18px;
	margin-top: 0px;
	margin-bottom: 0px;
}

.button-header-container {
	margin-top: 4em;
	margin-bottom: 1em;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.divider {
	height: 4px;
	background-color: #433054;
	flex: 1;
	align-self: 'center';
	border-radius: 2px;
}

.illustration {
	width: 70%;
	height: 70%;
	margin-left: 50px;
	margin-top: 50px;
	background-blend-mode: color-burn;
}

.social-auth-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 20px;
	margin-top: 0.4em;
}

.yellow-mural {
	position: absolute;
	top: -550px;
	right: 0;
	margin-right: -97px;
	transform: rotate(-20deg);
	width: 200px;
	height: 1000px;
	background-color: #25113a;
	opacity: 0.7;
	z-index: 4;
}

.green-mural {
	position: absolute;
	top: -550px;
	right: 0;
	margin-right: -47px;
	transform: rotate(-50deg);
	width: 200px;
	height: 1000px;
	background-color: #917bb9;
	opacity: 0.6;
	z-index: 3;
}

.red-mural {
	position: absolute;
	top: -530px;
	right: 0;
	transform: rotate(-70deg);
	margin-right: -47px;
	width: 200px;
	height: 1000px;
	background-color: #9e9dde;
	opacity: 0.5;
	z-index: 2;
}

.pink-mural {
	position: absolute;
	top: -1028px;
	right: 0;
	margin-right: -47px;
	transform: rotate(-78deg);
	width: 200px;
	height: 2000px;
	background-color: #cbe0f2;
	opacity: 0.4;
	z-index: 1;
}

@media only screen and (min-width: 800px) {
	.form-container {
		margin-right: 100px;
	}
}
